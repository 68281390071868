import React from "react";
import ReactDOM from "react-dom/client";
import GlobalStyles2 from "@livecasino/core/v2/GlobalStyle";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));
import "./index.css";

root.render(
    <>
        <GlobalStyles2 />
        <App />
    </>
);
